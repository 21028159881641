import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/UsecaesHeader";
import Container from "../../components/chat-channel/Container";
import AppsIntegrations from "../../components/common/usecases/appsIntegrations";
import ArrowRightICon from "../../components/common/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import KeyResources from "../../components/common/KeyResources";
import Cards from "../../components/common/knowledge/Cards";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { Slider } from "../../components/common/sliderHomePage";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/integration/card_1.png");
export const whitepaperImg = require("../../assets/img/key-resources/bc_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

const section_1 = require("../../assets/images/usecases/desktop/usecase_icon_1.png");
const section_2 = require("../../assets/images/usecases/desktop/usecase_icon_2.png");

const section_1_mob = require("../../assets/images/usecases/mobile/usecase_icon_1_mob.png");
const section_2_mob = require("../../assets/images/usecases/mobile/usecase_icon_2_mob.png");

const slackteam = require("../../assets/images/usecases/unlock_account/unlock_account_last.png");
const automation_steps = require("../../assets/images/usecases/unlock_account/unlock_steps.png");
const mob_onboard_steps = require("../../assets/images/usecases/mobile/mob_unlock_steps.png");

const aboveFoldImage = require("../../assets/images/usecases/unlock_account/unlock_account_header.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Deliver IT Process Automation on Unlock Account via Okta workflow automation",
    url:
      "https://blog.workativ.com/deliver-it-process-automation-on-unlock-account-password-reset-and-access-management-via-okta-workflow-automation/",
    link: "Read Blog",
    alt: "Unlock Account Automation"
  },
  {
    image: whitepaperImg,
    header:
      "Deliver IT Process Automation on Unlock Account via Azure AD workflow automationn",
    url:
      "https://blog.workativ.com/deliver-it-process-automation-on-unlock-account-password-reset-and-access-management-via-microsoft-azure-active-directory-workflow-automation/",
    link: "Read Blog",
    alt: "Unlock Account Blog"
  },
  {
    image: videoImg,
    header:
      "How to Automate Unlock-account in Microsoft Azure via Workativ chatbot",
    url: "https://youtu.be/ns4HX5ggV78",
    link: "Watch Video",
    alt: "Unlock Account Video"
  }
];
export const cardData = [
  {
    href: null,
    header: "Problem",
    content:
      "Account lockouts for ensuring security are vital, but unlocking accounts is a highly time-consuming task for the helpdesk. ",
    backgroundClass: "card_one"
  },
  {
    href: null,
    header: "Solution",
    content:
      "Reducing help desk calls by offering a self-service option while maintaining the security of desktop workstations.",
    backgroundClass: "card_two"
  },
  {
    href: null,
    header: "Impact",
    content:
      "Enabling a conversational chatbot for users to unlock accounts autonomously improves productivity.",
    backgroundClass: "card_three"
  }
];

const headerMenu = [
  {
    menu: "Overview",
    href: "#section_1",
    id: "section_1"
  },
  {
    menu: "Why self-service account unlock",
    href: "#section_2",
    id: "section_2"
  },
  {
    menu: "Unlock Account automation steps",
    href: "#section_3",
    id: "section_3"
  },
  {
    menu: "Unlock Account chatbot on Slack and Teams",
    href: "#section_4",
    id: "section_4"
  },
  {
    menu: "Other popular use cases",
    href: "#section_5",
    id: "section_3"
  }
];

const sectionImg = [
  {
    img: section_1,
    mobImage: section_1_mob,
    alt: "Self-service ‘unlock account’",
    header: "Self-service ‘unlock account’",
    paragraph:
      "Workativ helps to reduce the ‘unlock account’ requests that reach the helpdesk by assisting companies in providing a conversational chatbot that auto-resolves the request and helps employees get back to their work instantly. Using Workativ, instead of using a static self-service portal, your employees can self-resolve the issue from Slack and Microsoft Teams. Through self-service, calls to the helpdesk get deflected, and issues get resolved instantly. "
  },
  {
    img: section_2,
    mobImage: section_2_mob,
    alt: "‘Unlock account’ automation",
    header: "‘Unlock account’ automation",
    paragraph:
      "Workativ’s no-code workplace automation platform and one-click integration with multiple Identity and Access applications let you create an ‘unlock account’ automation workflow instantly. To reduce the number of requests reaching the IT support team, companies can create a secure process to enable employees to ‘unlock account’ through AI self-service. By integrating with Active Directory, or existing password management apps, user management/access management apps, you can create the desired workflow and deploy an AI chatbot to service ‘unlock account’ requests. "
  }
];

export default function HomePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState("Overview");

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("Overview");

  const listenScrollEvent = e => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Unlock Account Automation - IT request automation software - Workativ "
        description="Unlock Account automation solution to minimize calls to helpdesk. Deploy Unlock Account chatbot for Microsoft Teams and Slack now."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={aboveFoldImage}
        ogTitle="Unlock Account Automation - IT request automation software - Workativ "
        ogDescription="Deliver employee support automation instantly with no-code Conversation AI Chatbot and Process automation for Teams and Slack. Connect with 50+ Apps and 400+ workflows instantly to deliver next-gen HR and IT Support."
      />
      <Container>
      <Layout logoFor="ASSISTANT">
          <section className={header}>
            {/* <div className="container-fluid bg_apps_usecaes">
              <div className="row">
                <div className="container">
                  <div className="sticky_menu">
                    <span>Unlock Account :</span>
                    <ul>
                      {headerMenu.map(menu => (
                        <li>
                          <AnchorLink
                            offset={() => 160}
                            href={menu.href}
                            className={
                              isActive === menu.menu
                                ? "url_manipulation active"
                                : "url_manipulation "
                            }
                            onClick={() => {
                              setIsActive(menu.menu);
                            }}
                          >
                            {" "}
                            {menu.menu}
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            <RegisterSection
              backgroundColor={"bg_apps_usecaes "}
              rightImage={aboveFoldImage}
              altImage={"Unlock Account Automation"}
              videoToken={"__NO_VIDEO_AVAILABLE__"}
              headerMenu={headerMenu}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Unlock Account Automation
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Strengthen end user security and minimize calls to the
                  helpdesk with the automated resolution of unlocking accounts. 
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            {/* {isSmall ? null : <OnScrollPopup />} */}

            <div className="mob_sticky_wrapper">
              {/* <div className="dropdown wv-dropdown">
                <h4>Unlock Account :</h4>
                <button
                  className={`link ${isOpen ? "active" : ""}`}
                  onClick={() => setOpen(!isOpen)}
                >
                  {dropdown}
                </button>
                {headerMenu.map(menu => (
                  <div
                    onClick={() => setOpen(!isOpen)}
                    id="myDropdown"
                    className={`accordion-item submenu dropdown-content ${
                      !isOpen ? "collapsed" : ""
                    }`}
                  >
                    {" "}
                    <li className={menu.id}>
                      <AnchorLink
                        offset={460}
                        className="url_manipulation"
                        href={menu.href}
                        onClick={() => setDropdown(menu.menu)}
                      >
                        {menu.menu}
                      </AnchorLink>
                    </li>
                  </div>
                ))}
              </div> */}
              <AppsIntegrations
                header={"Unlock Account made easy with your favorite apps"}
                cards={appsName}
                id={"section_1"}
              />
              <section className="boarding_wrapper">
                <div className="container">
                  <div className="row">
                    {sectionImg.map((card, index) => {
                      if (index % 2 == 0) {
                        return (
                          <div className="boarding_wrapper_container">
                            <div className="row">
                              <div className="col-md-12 col-lg-3 col-12">
                                <picture>
                                  <source
                                    media="(max-width:768px)"
                                    srcSet={card.mobImage}
                                  />
                                  <img loading = "lazy" src={card.img} alt={card.alt}></img>
                                </picture>
                              </div>
                              <div className="col-md-12 col-lg-9 col-12 pad_left_30">
                                <h4>{card.header}</h4>
                                <p>{card.paragraph} </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div className="boarding_wrapper_container">
                          <div className="row">
                            <div className="col-md-12 col-lg-9 col-12 pad_right_30">
                              <h4>{card.header}</h4>
                              <p>{card.paragraph} </p>
                            </div>
                            <div className="col-md-12 col-lg-3 col-12">
                              <picture>
                                <source
                                  media="(max-width:768px)"
                                  srcSet={card.mobImage}
                                />
                                <img loading = "lazy" src={card.img} alt={card.alt}></img>
                              </picture>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Cards
                id={"section_2"}
                cards={cardData}
                header={"Why self-service account unlock"}
              />
              <section className="automation_steps" id={"section_3"}>
                <div className="container">
                  <h2>How Unlock Account automation works</h2>
                  <picture>
                    <source
                      media="(max-width:768px)"
                      srcSet={mob_onboard_steps}
                    />
                    <img loading = "lazy"
                      src={automation_steps}
                      alt="How Unlock Account automation works"
                    />
                  </picture>
                </div>
              </section>
              <section className="slack_teams_wrapper" id={"section_4"}>
                <LeftFormRightImage
                  image={slackteam}
                  altImage={"Unlock Account Chatbot"}
                >
                  <LeftFormRightImage.Header>
                    <h3>
                      Unlock Account chatbot for Microsoft Teams and Slack
                    </h3>
                  </LeftFormRightImage.Header>
                  <LeftFormRightImage.SubHeader>
                    <p>
                      A next-gen chatbot that understands a user’s request and
                      enables them to unlock their account is the best solution
                      to deflect low-level issues to the helpdesk team. Enable
                      self-service on Slack and Teams with Workativ chatbot for
                      automated resolution of ‘unlock account’ requests and
                      watch the productivity graph improve at the workplace.
                    </p>
                  </LeftFormRightImage.SubHeader>
                  <LeftFormRightImage.Link>
                    <div className="card_link_landing">
                      <SlackAndTeams />
                    </div>
                  </LeftFormRightImage.Link>
                </LeftFormRightImage>
              </section>
              <Slider
                title={"Other popular use cases"}
                sliderData={sliderData}
                id={"section_5"}
              />
            </div>

            <div className="popular_workflows_">
            <KeyResources cards={resoucesData} />
            </div>
          </section>
          {/* <GoToPopup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

export const leave = require("../../assets/images/usecases/common/leave_management.png");
export const offboarding = require("../../assets/images/usecases/common/offboarding.png");
export const payroll = require("../../assets/images/usecases/common/payroll.png");
export const onboardinng = require("../../assets/images/usecases/common/onboardinng.png");
export const ticketing = require("../../assets/images/usecases/common/ticketing.png");
export const reset = require("../../assets/images/usecases/common/reset.png");
export const user_provision = require("../../assets/images/usecases/common/user_provision.png");

const sliderData = [
  {
    header: "Employee Offboarding",
    alt: "Employee Offboarding",
    image: offboarding,
    content:
      "Automate employee offboarding and remove the risk of ex-employees’ access to your systems and network ",
    link: "Learn more",
    url: "/use-cases/employee-offboarding-automation"
  },
  {
    header: "Password Reset",
    alt: "Password Reset Automation",
    image: reset,
    content:
      "Expedite issue resolution for employees and reduce service desk call volume with ‘password reset’ automation.",
    link: "Learn more",
    url: "/use-cases/reset-password-automation"
  },
  {
    header: "Employee Onboarding",
    alt: "Employee Onboarding Automation",
    image: onboardinng,
    content:
      "Simplify the onboarding tasks for modern HR teams and deliver a positive onboarding experience.",
    link: "Learn more",
    url: "/use-cases/employee-onboarding-automation"
  },

  {
    header: "Payroll Enquiry",
    alt: "Payroll Enquiry Automation",
    image: payroll,
    content:
      "Streamline automation for delivering payroll information to employees with zero waiting time.",
    link: "Learn more",
    url: "/use-cases/payroll-enquiry-automation"
  },
  {
    header: "AI Ticketing",
    alt: "AI Ticketing Automation",
    image: ticketing,
    content:
      "Reduce contact with the helpdesk for repetitive issues and transform the employee experience with AI.",
    link: "Learn more",
    url: "/use-cases/ai-ticketing"
  },
  {
    header: "User Provisioning",
    alt: "User Provisioning Automation",
    image: user_provision,
    content:
      "Reduce costs and improve employee experience with user provisioning automation.",
    link: "Learn more",
    url: "/use-cases/user-provisioning-automation"
  },
  {
    header: "Leave management",
    alt: "Leave management automation",
    image: leave,
    content:
      "Simplify PTO inquiries and requests for employees with an automated, intelligent and user-friendly chatbot.",
    link: "Learn more",
    url: "/use-cases/leave-management-automation"
  }
];

const zure = require("../../assets/images/icons-web/zure.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const twilio = require("../../assets/images/icons-web/twilio.svg");
const ms_office = require("../../assets/images/icons-web/ms_office.svg");
const freshdesk = require("../../assets/images/icons-web/fd_1.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const zohodesk = require("../../assets/images/icons-web/zohodesk.svg");

const appsName = [
  {
    image: zure,
    alt: "Azure"
  },
  {
    image: okta,
    alt: "okta"
  },
  {
    image: jumpcloud,
    alt: "jumpcloud"
  },
  {
    image: ms_office,
    alt: "ms_office"
  },
  {
    image: twilio,
    alt: "twilio"
  },
  {
    image: freshservice,
    alt: "freshservice"
  },
  {
    image: freshdesk,
    alt: "freshdesk"
  },
  {
    image: zohodesk,
    alt: "zohodesk"
  }
];

export const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
